import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "BaseUrl";
import { useNavigate } from "react-router-dom";
import { FaBolt, FaClock, FaSpinner, FaWhatsapp } from "react-icons/fa";
import ReactWhatsapp from "react-whatsapp";

const PackageCard = ({ image, title, price, onClick, days, nights, hours }) => (
  <div
    className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105 cursor-pointer"
    onClick={onClick}
  >
    <img src={image[0]} alt={title} className="w-full h-48 object-cover" />
    <div className="p-4">
      <h3 className="text-xl font-semibold mb-2 truncate">{title}</h3>
      <div className="flex justify-between mt-3">
        <div>
        <div className="flex gap-1">
          <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
            <FaClock />
            <span> {days} Days </span>
          </div>
          <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
            <FaClock />
            <span> {nights} Nights </span>
          </div>
          <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
            <FaClock />
            <span> {hours} Hours </span>
          </div>
          </div>
          <div className="flex gap-x-2 items-center text-sm font-semibold text-orange-500">
            <FaBolt />
            <span> {price} AED</span>
          </div>
        </div>
        <div>
          <ReactWhatsapp
            number="+971551085136"
            message="Could you tell me about your tour Packages?"
            style={{
              backgroundColor: "#F0F0F0",
              color: "black",
              padding: "10px",
              display: "flex",
              alignItems: "center",
              zIndex: 1000,
              borderRadius: "8px",
              // boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            }}
          >
            <FaWhatsapp style={{ fontSize: "24px", marginRight: "10px" }} />
            WhatsApp
          </ReactWhatsapp>
        </div>
      </div>
    </div>
  </div>
);

const Packages = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/getpackages`);

        console.log("API Response:", response.data);

        const packageData = Array.isArray(response.data)
          ? response.data
          : response.data.packages;

        if (packageData) {
          const formattedPackages = packageData.map((pkg) => ({
            ...pkg,
            image: pkg.image,
          }));
          setPackages(formattedPackages);
        } else {
          throw new Error("No packages found in the API response");
        }

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  const handlePackageClick = (pkg) => {
    navigate("/tour-detail", { state: { selectedPackage: pkg } });
  };

  if (loading) {
    return <div> <FaSpinner className="animate-spin text-xl text-center" /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {/* <BnbNav /> */}
      <div className="container mx-auto px-4 py-8 min-h-screen">
        {/* <h1 className="text-4xl font-bold text-center mb-12">Packages</h1> */}
        <div className="flex flex-col items-center justify-center space-y-2 md:p-16 p-3 mt-10 ">
          {" "}
          <h1 className="md:text-4xl text-2xl font-medium text-center">
            Discover the Perfect Travel Package for You!
          </h1>{" "}
          <p className="md:text-xl text-lg font-normal text-[#4B4B4B] md:w-[526px] w-full text-center">
            Start Planning Your Next Adventure: Find Tailored Experiences and
            Explore the World Effortlessly.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {packages.map((pkg, index) => (
            <PackageCard
              key={index}
              image={pkg.image}
              title={pkg.title}
              rating={pkg.rating}
              days={pkg.days}
              nights={pkg.nights}
              hours={pkg.hours}
              price={pkg.price}
              onClick={() => handlePackageClick(pkg)} // Navigate to detail page on click
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Packages;
