import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaSpinner, FaClock } from "react-icons/fa";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { IoMdArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";

const TourDetail = () => {
  const navigate = useNavigate();
  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const location = useLocation();
  const { selectedPackage } = location.state || {};
  const [loading, setLoading] = useState(false);
  const {
    title,
    rating,
    days,
    nights,
    hours,
    price,
    image = [],
    description,
    date,
    startTime,
    endTime,
    policy,
    pickupTime,
    dropoffTime,
  } = selectedPackage || {};
  const [showReviewForm, setShowReviewForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    adults: "",
    children: "",
    infants: "",
    bookingDate: "",
  });
  const [reviewData, setReviewData] = useState({
    name: "",
    description: "",
    review_start: 0,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const adults = parseInt(formData.adults, 10) || 0;
    const children = parseInt(formData.children, 10) || 0;
    const infants = parseInt(formData.infants, 10) || 0;

    const adultPrice = price;
    const childPrice = price * 0.7; 
    const infantPrice = 0;

    const totalAdultPrice = adults * adultPrice;
    const totalChildPrice = children * childPrice;
    const totalInfantPrice = infants * infantPrice;
    const totalPrice = totalAdultPrice + totalChildPrice + totalInfantPrice;

    const bookingData = {
      ...formData,
      title,
      days,
      nights,
      hours,
      price: totalPrice,
      date,
      image,
      description,
      totalChildPrice,
      childPrice,
      adultPrice,
      totalAdultPrice,
      email: formData.email,
      adults,
      children,
      infants,
      name: formData.name,
    };
    try {
      navigate("/payment", {
        state: {
          bookingData,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error("Error creating booking. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  const handleStarClick = (rating) => {
    setReviewData({ ...reviewData, review_start: rating });
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("name", reviewData.name);
    formData.append("description", reviewData.description);
    formData.append("review_start", reviewData.review_start);

    try {
      const response = await axios.post(`${BaseUrl}/api/review`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success(response.data.msg || "Review submitted successfully!");
      setReviewData({
        name: "",
        description: "",
        review_start: 0,
      });
      setShowReviewForm(false);
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("Failed to submit review. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg mt-28">
      <div className="text-[40px] cursor-pointer" onClick={() => navigate("/")}>
        <IoMdArrowBack />
      </div>
      <div className="flex flex-col md:flex-row pt-10">
        <div className="md:w-2/3 pr-4">
          <div className="flex justify-between">
            <h1 className="text-3xl font-bold mb-2">{title}</h1>
            <h1 className="text-2xl font-bold mb-2">AED {price}</h1>
          </div>

          <div className="mb-4">
            <p>{formattedDate}</p>
          </div>
          <h2>Durations</h2>
          <div className="flex gap-3 items-center">
            <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
              <FaClock />
              <span> {days} Days</span>
            </div>
            <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
              <FaClock />
              <span> {nights} Nights </span>
            </div>
            <div className="flex justify-start gap-x-2 items-center text-sm font-semibold text-gray-600 mb-2">
              <FaClock />
              <span> {hours} Hours </span>
            </div>
          </div>

          <div className="">
            {image && image.length > 0 ? (
              <Slider {...settings} className="w-full">
                {Array.isArray(image) ? (
                  image.map((img, index) => (
                    <div key={index}>
                      <img
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className="w-full md:h-[400px] rounded-md h-full object-cover"
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <img
                      src={image}
                      alt="Default Slide"
                      className="w-full h-48 object-cover"
                    />
                  </div>
                )}
              </Slider>
            ) : (
              <p className="text-gray-500">No images available.</p>
            )}
          </div>
          <div className=" pt-6">
            <p
              className="mt-5"
              dangerouslySetInnerHTML={{
                __html: description.replace(/\n/g, "<br/>"),
              }}
            />
          </div>

          <div className="pt-5 pb-3">
            <h2 className="text-md">Timings:</h2>
            <div className="flex items-center gap-1 pt-2">
              <p className="">{startTime}</p>
              <p>to</p>
              <p className="">{endTime}</p>
            </div>
          </div>
          <div className="md:w-[700px]  w-full p-4 border border-gray-300 rounded-md shadow-md bg-white">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Pick up Time & Drop off Timings
            </h2>
            <div className=" pt-2 flex items-center justify-between">
              <h3 className="text-sm font-bold text-gray-600">Pick up Time</h3>
              <p className="text-sm text-gray-700 mt-1">{pickupTime}</p>
            </div>
            <div className="border-t border-gray-200  flex items-center justify-between">
              <h3 className="text-sm font-bold text-gray-600">Drop off Time</h3>
              <p className="text-sm text-gray-700 mt-1">{dropoffTime}</p>
            </div>
          </div>
          <div className="md:w-[700px] mt-8 w-full p-4 border border-gray-300 rounded-md shadow-md bg-white">
            <h2 className="text-lg font-semibold text-gray-800 mb-2">
              Booking Terms
            </h2>
            <div className="border-t border-gray-200 pt-2">
              <h3 className="text-sm font-bold text-gray-600 uppercase">
                Cancellation Policy Before Service Begins
              </h3>
              <p className="text-sm text-gray-700 mt-1">{policy}</p>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 flex md:pt-16 pt-0">
          <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-2xl font-bold mb-6 text-center">
              Booking Form
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Email"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Booking Date
                </label>
                <input
                  type="date"
                  name="bookingDate"
                  value={formData.bookingDate}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Booking Date"
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Adults
                </label>
                <input
                  type="number"
                  name="adults"
                  value={formData.adults}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Number of adults"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Children
                </label>
                <input
                  type="number"
                  name="children"
                  value={formData.children}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Number of children"
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold mb-2">
                  Infants
                </label>
                <input
                  type="text"
                  name="infants"
                  value={formData.infants}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  placeholder="Enter your Infants"
                />
              </div>
              <button
                disabled={loading}
                type="submit"
                className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-all duration-300"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <FaSpinner className="animate-spin mr-2" />
                    Processing...
                  </div>
                ) : (
                  "Book now"
                )}
              </button>
            </form>
            <div className="p-4 bg-gray-100 rounded-lg">
              <h2 className="text-lg font-semibold mb-4 text-center text-gray-800">
                Details
              </h2>
              <div className="overflow-x-auto">
                <table className="w-full table-auto border-collapse border border-gray-300">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-600">
                        Category
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-600">
                        Age
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-left text-sm font-medium text-gray-600">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        Infants
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        0-6 years
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        Free
                      </td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        Children
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        7-12 years
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        70%
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        Adults
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        13 & above
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-sm text-gray-700">
                        Full
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300 ease-in-out"
          onClick={() => setShowReviewForm(!showReviewForm)}
        >
          {showReviewForm ? "Close Review Form" : "Give Review"}
        </button>
      </div>

      {showReviewForm && (
        <div className="p-4 bg-gray-100 rounded-lg mt-4 shadow-md">
          <h2 className="text-xl font-bold mb-4">Submit Your Review</h2>
          <form onSubmit={handleReviewSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Rating
              </label>
              <div className="flex space-x-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    className={`text-2xl ${
                      reviewData.review_start >= star
                        ? "text-yellow-400"
                        : "text-gray-400"
                    }`}
                    onClick={() => handleStarClick(star)}
                  >
                    ★
                  </button>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={reviewData.name}
                onChange={handleReviewChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Description
              </label>
              <textarea
                name="description"
                value={reviewData.description}
                onChange={handleReviewChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-all"
            >
              {loading ? "Submitting..." : "Submit Review"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default TourDetail;
