import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { jsPDF } from "jspdf";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";
import Slider from "react-slick";

const stripePromise = loadStripe(
  process.env.REACT_APP_PK_KEY
  // "pk_test_51NdsOuLoVAgYXxqhgSFrDnwb9b4eI7GEZFhMfzXAwnxFGx1vSgGVSoHL2lX6uYuKa1rwl3tVge4WvmPejqNi62AG00lMKyfhRM"
);

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const navigate = useNavigate();

  const { bookingData } = location.state || {};
  const {
    bookingId,
    price,
    title,
    childPrice,
    totalChildPrice,
    image,
    description,
    adultPrice,
    totalAdultPrice,
    email,
    adults,
    children,
    infants,
    name,
  } = bookingData;
  console.log("bookingData", bookingData);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  useEffect(() => {
    if (price) {
      fetch(`${BaseUrl}/api/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ bookingId, price, email }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.clientSecret) {
            setClientSecret(data.clientSecret);
          } else {
            setErrorMessage("Error: clientSecret is missing in the response");
          }
        })
        .catch(() => {
          setErrorMessage("Error fetching client secret.");
        });
    }
  }, [bookingId, price]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;
    if (!clientSecret) {
      console.error("Client secret is missing.");
      setErrorMessage("Client secret is missing.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {},
        },
      }
    );

    if (error) {
      setErrorMessage(error.message);
      console.error("Payment failed:", error.message);
      setLoading(false);
    } else if (paymentIntent.status === "requires_capture") {
      try {
        bookingData.paymentIntentId = paymentIntent.id;

        const bookingResponse = await axios.post(
          `${BaseUrl}/api/bookings`,
          bookingData
        );

        if (bookingResponse.status === 201) {
          toast.success("Payment and booking successful!");
          setPaymentSuccess(true);
        } else {
          setErrorMessage(
            "Booking was not successful, please contact support."
          );
        }
      } catch (bookingError) {
        setErrorMessage(
          "Payment was successful but there was an error processing the booking."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const generateReceipt = () => {
    const doc = new jsPDF();

    // Header
    doc.setFontSize(22);
    doc.setFont("helvetica", "bold");
    doc.text("KKRA Travel & Tourism", 105, 20, { align: "center" });

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.text("Payment Receipt", 105, 30, { align: "center" });

    doc.setFontSize(12);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 180, 40, {
      align: "right",
    });

    // Divider
    doc.line(10, 45, 200, 45); // Horizontal line

    // Booking Details
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Personal Details", 10, 55);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Name: ${name}`, 10, 65);
    doc.text(`Title: ${title}`, 10, 75);
    doc.text(`Email: ${email}`, 10, 85);

    // Divider
    doc.line(10, 90, 200, 90); // Horizontal line

    // Price Details
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Summary", 10, 100);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const priceDetails = [
      ["Number of Infants:", `${infants}`],
      ["Number of Child:", `${children}`],
      ["Total Child Price:", `AED ${totalChildPrice?.toFixed(2)}`],
      ["Number of Adult:", `${adults}`],
      ["Total Adult Price:", `AED ${totalAdultPrice?.toFixed(2)}`],
    ];

    let yPosition = 110;
    priceDetails.forEach(([label, value]) => {
      doc.text(label, 10, yPosition);
      doc.text(value, 180, yPosition, { align: "right" });
      yPosition += 10;
    });

    // Divider
    doc.line(10, yPosition + 5, 200, yPosition + 5); // Horizontal line

    // Total Price below the line
    yPosition += 15;
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Total Price:", 10, yPosition);
    doc.text(`AED ${price?.toFixed(2)}`, 180, yPosition, { align: "right" });

    // Footer
    yPosition += 25;
    doc.setFontSize(12);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(100);
    doc.text(
      "Thank you for booking with KKRA Travel & Tourism!",
      105,
      yPosition,
      { align: "center" }
    );

    // Contact Information
    yPosition += 10;
    doc.setFont("helvetica", "normal");
    doc.text(
      "If you have any questions, feel free to contact us at info@kkratraveltours.com",
      105,
      yPosition,
      { align: "center" }
    );

    // Save the PDF
    doc.save("payment-receipt.pdf");
  };

  return (
    <div className="max-w-6xl mx-auto p-6 pt-[100px]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold mb-4">{title}</h2>
            <h2 className="text-2xl font-bold mb-4">AED {price?.toFixed(2)}</h2>
          </div>
          <div className="">
            {image && image.length > 0 ? (
              <Slider {...settings} className="w-full">
                {Array.isArray(image) ? (
                  image.map((img, index) => (
                    <div key={index}>
                      <img
                        src={img}
                        alt={`Slide ${index + 1}`}
                        className="w-full md:h-[400px] rounded-md h-full object-cover"
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <img
                      src={image[0]}
                      alt="Default Slide"
                      className="w-full h-48 object-cover"
                    />
                  </div>
                )}
              </Slider>
            ) : (
              <p className="text-gray-500">No images available.</p>
            )}
          </div>
          <p
            className="text-gray-600"
            dangerouslySetInnerHTML={{
              __html: description.replace(/\n/g, "<br/>"),
            }}
          />
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          {errorMessage && (
            <div className="text-red-500 mb-4">{errorMessage}</div>
          )}

          {paymentSuccess ? (
            <div className="text-center mb-4">
              <h2 className="text-2xl font-semibold text-green-600 mb-4">
                Payment Successful!
              </h2>
              <button
                className="bg-blue-600 text-white py-2 px-4 rounded-lg"
                onClick={generateReceipt}
              >
                Download Receipt
              </button>
            </div>
          ) : (
            <div className="rounded-lg p-6 max-w-xl mx-auto">
              <h2 className="text-xl font-semibold text-gray-800 mb-4 text-center">
                Price Details
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-blue-50 border border-blue-300 rounded-md p-4 text-center">
                  <h3 className="text-sm font-bold text-blue-800 mb-2">
                    Child Price (Per Child)
                  </h3>
                  <p className="text-lg font-semibold text-blue-600">
                    {childPrice?.toFixed(2)}
                  </p>
                </div>
                <div className="bg-blue-50 border border-blue-300 rounded-md p-4 text-center">
                  <h3 className="text-sm font-bold text-blue-800 mb-2">
                    Total Child Price
                  </h3>
                  <p className="text-lg font-semibold text-blue-600">
                    {totalChildPrice?.toFixed(2)}
                  </p>
                </div>

                <div className="bg-green-50 border border-green-300 rounded-md p-4 text-center">
                  <h3 className="text-sm font-bold text-green-800 mb-2">
                    Adult Price (Per Adult)
                  </h3>
                  <p className="text-lg font-semibold text-green-600">
                    {adultPrice?.toFixed(2)}
                  </p>
                </div>
                <div className="bg-green-50 border border-green-300 rounded-md p-4 text-center">
                  <h3 className="text-sm font-bold text-green-800 mb-2">
                    Total Adult Price
                  </h3>
                  <p className="text-lg font-semibold text-green-600">
                    {totalAdultPrice?.toFixed(2)}
                  </p>
                </div>

                {/* Total Price */}
                <div className="bg-yellow-50 border border-yellow-300 rounded-md p-4 col-span-1 md:col-span-2 text-center">
                  <h3 className="text-sm font-bold text-yellow-800 mb-2">
                    Total Price
                  </h3>
                  <p className="text-xl font-bold text-yellow-600">
                    {price?.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          )}
          {!paymentSuccess && (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <CardElement className="p-4 border rounded-lg" />
              </div>

              <button
                type="submit"
                className={`w-full bg-blue-600 text-white py-2 px-4 rounded-lg ${
                  loading ? "opacity-50" : ""
                }`}
                disabled={!stripe || loading}
              >
                {loading ? "Processing..." : `Pay ${price?.toFixed(2)} AED`}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default function StripeContainer() {
  return (
    <Elements stripe={stripePromise}>
      <Payment />
    </Elements>
  );
}
